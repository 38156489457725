import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Value from '~/components/molecules/Value'

export default function Values({key, index, ...props}) {

  const section = props.values
  return (
    <section id={section.fieldGroupName + index}  className="my-20 md:my-36 overflow-hidden">
      <div className="container xl:grid grid-cols-12">
        <header className="col-span-10 col-start-2 md:grid grid-cols-12 mb-16 md:mb-0">
          <div className="col-span-5 ">
            <TextCard 
              className="text-center md:text-left " 
              {...section.textCard} />
          </div>
        </header>
        <div className="col-span-10 col-start-2 flex flex-col md:flex-row flex-wrap justify-end mt-40">
          {section.values.map((item, i) => (
            <div className="md:w-[60%] md:even:w-[40%] md:last:w-[40%] mb-12 md:mb-40 md:even:-mt-40 md:last:-mt-40 md:last:mb-0">
              <Value key={`value${i}`} className="text-center md:text-left max-w-sm mx-auto md:mx-0 " {...item}/>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
